import { useContext,useState} from 'react';
import { NavLink,useLocation,useNavigate} from 'react-router-dom';
import Logo from '../AppAssets/Logo.png';
import AuthContext from '../AppContext/AuthContext';
import {BsChevronDown} from 'react-icons/bs'


const AppHeader = () => {
  const navigate = useNavigate();
  const {handleLogOut,UserName} =useContext(AuthContext);
  const Location=useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const logOutHandler=()=>{
    setShowDropdown(!showDropdown);
    handleLogOut()
  }

  const handleNavigation = () => {
    navigate('/designs'); 
};


  return (
    <div>
    <div className="flex px-10 pt-2 w-full items-center font-specialElite flex-col [@media(min-width:704px)]:flex-row " style={{backgroundColor:"#FFFFFF"}}  >
    <div onClick={Location.pathname!=="designs"?()=>handleNavigation():null} className="w-[350px] flex flex-col items-center cursor-pointer [@media(min-width:704px)]:items-start">
      <div className="flex flex-col items-center">
      <img src={Logo} className="w-[170px] h-[50px]" alt="Print Center" />
    <span className="text-md mt-2">(pronounced printer)</span>
    </div>
    </div>


           
            {/* <div 
          className={
            `text-black   w-[75%] mx-12  rounded  bg-[#1F3566] ${ShowMessage===true?'visible':'invisible'}  `
          }
        >
          
          <span className={`inline-block align-middle pt-2 pb-2  ml-5 ${SuccessMessage===true?'text-[#FFFFFF]':'text-red-600'}  font-bold`}>
         {Message}
          </span>
          <button
            className={`float-right mr-2 pt-2 pb-2 align-middle text-3xl font-bold rounded-full ${SuccessMessage===true?'text-[#FFFFFF]':'text-red-600'}`}
           onClick={()=>setShowMessage(false)}
          >
            <span>×</span>
          </button>
        </div> */}
        <div className='rounded-lg px-4 font-specialElite font-light  h-12 items-center flex justify-between  space-x-4 mx-2 w-full' >
          <div className='flex gap-6 md:gap-10 lg:gap-16'>
          
          <NavLink style={{ textShadow: `${Location.pathname==="/newsletterapp"?"0px 4px 4px rgba(0, 0, 0, 0.25)":"none"}` }}  className={` font-specialElite  font-light hover:text-[#9A0000] ${Location.pathname==="/newsletterapp"?"text-[#9A0000] ":""}     active:text-[#9A0000]  `} to="/newsletterapp"   >Documents</NavLink>
          <NavLink style={{ textShadow: `${Location.pathname==="/calendarapp"?"0px 4px 4px rgba(0, 0, 0, 0.25)":"none"}` }} className={` font-specialElite font-light hover:text-[#9A0000] ${Location.pathname==="/calendarapp"?"text-[#9A0000]":""} active:text-[#9A0000] `}  to="/calendarapp" >Calendar </NavLink>
          <NavLink style={{ textShadow: `${Location.pathname==="/slideapp"?"0px 4px 4px rgba(0, 0, 0, 0.25)":"none"}` }} className={` font-specialElite font-light hover:text-[#9A0000] ${Location.pathname==="/slideapp"?"text-[#9A0000]":""} active:text-[#9A0000]  `}  to="/slideapp" >Slides </NavLink>
          <NavLink style={{ textShadow: `${Location.pathname==="/designs"?"0px 4px 4px rgba(0, 0, 0, 0.25)":"none"}` }} className={`font-specialElite font-light hover:text-[#9A0000] ${Location.pathname==="/designs"?"text-[#9A0000]":""} active:text-[#9A0000]  `} to="/designs" >Designs</NavLink>
          {/* <NavLink className={`h-full py-2 px-2 font-specialElite font-light hover:bg-[#E8E8E8] ${Location.pathname==="/content"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content" >Content </NavLink>
          <NavLink className={`h-full py-2 px-2 font-specialElite font-light hover:bg-[#E8E8E8] ${Location.pathname==="/content-creater"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/content-creater" >Content Creator</NavLink> */}
          
         
      {/* <NavLink className={`h-full py-2 px-2 font-specialElite font-light hover:bg-[#E8E8E8] ${Location.pathname==="/help"?"bg-[#E8E8E8]":""} rounded-md active:bg-[#E8E8E8] `} to="/help" >Help </NavLink> */}
          </div>

          <div className='mx-2 '>
          <div className="relative item-center"  
                    >
                  <button
                   onClick={handleDropdownToggle}
                    className=" inline-flex items-center space-x-2 hover:text-[#9A0000]   rounded-md "
                  >
                     
                    {/* <span>My Account</span> */}
                    <span className='font-specialElite font-light'>{UserName}</span>
                    <BsChevronDown className="block ml-1"/>
                   
                  </button>
                  {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48   bg-white rounded-md shadow-lg z-[400]">
                      <div className="py-1">
                        
                            <NavLink
                            onClick={handleDropdownToggle}
                              to="/profile"
                              className={`block px-4 py-2 text-sm active:text-[#9A0000]  ${Location.pathname==="/profile"?"text-[#9A0000]":""} hover:text-[#9A0000] `}
                              style={{ textShadow: `${Location.pathname==="/profile"?"0px 4px 4px rgba(0, 0, 0, 0.25)":"none"}` }}
                            >
                              Profile
                            </NavLink>
                        
                        
                      
                        <button
                          onClick={logOutHandler}
                          className="block px-4 py-2 text-sm    hover:text-[#9A0000] w-full text-left"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
          </div>
        </div>
       
            {/* <div className='inline-flex space-x-8 '>

              <div className='inline-flex border border-blue-500 hover:border-transparent rounded '>
              <button onClick={handleDownloadImage} disabled={ExportLoader===true?true:false} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 rounded">
                  
                  {
                    ExportLoader===true?"Processing":"Export"
                  }
               </button>
               <select onChange={handleChangeFormat}  className="bg-transparent text-blue-700 font-semibold hover:text-white hover:bg-blue-500 rounded  ">
       
            {
            ExportFormats.map((item,index)=>(
              <option key={index}  value={item.format}> {item.format} </option>
            ))
            }
       
       
         </select>
              </div>


              <button onClick={CurrentSavedItem===-1?()=>setSaveTemplateFormToggle(true):SaveCurrentNewsLetter} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                  Save
               </button>
               
               <ReactToPrint
            
        trigger={()=>{
            return ( <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Print
         </button>)
        }}
        content={()=>PrintRef.current}
        
        documentTitle='Print Center'
        />
             
              
            </div> */}
           
          
    </div>
    <div className="border-t border-black my-2"></div>
    </div>
  )
}

export default AppHeader